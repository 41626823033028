<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        {{ headerTitle }}
      </div>
    </div>

    <container />
    <div id="container-modal-delete-branch" />
  </div>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import Container from './container'

export default {
  components: {
    Container
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  }
}
</script>
