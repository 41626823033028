<template>
  <div>
    <div class="filter-container">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
            <label
              class="uk-form-label filter-margin"
              for="cari"
            >Cari</label>
            <div class="uk-form-controls">
              <input
                id="search-name"
                v-model="metaFilter.name"
                class="uk-input"
                type="text"
                placeholder="Cari cabang"
              >
            </div>
          </div>
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="tanggal_diubah"
            >Tanggal Diubah</label>
            <div class="uk-form-controls">
              <div
                uk-grid
                class="uk-grid-small"
              >
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinStart"
                    v-model="metaFilter.updated_start"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <datepicker
                    ref="joinEnd"
                    v-model="metaFilter.updated_end"
                    input-class="uk-input"
                    placeholder="DD MM YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <label
              class="uk-form-label filter-margin"
              for="status"
            > Status </label>
            <div class="uk-form-controls">
              <multiselect
                id="status"
                v-model="statusChoose"
                v-validate="'required'"
                name="status"
                label="name"
                track-by="name"
                placeholder="Semua"
                :options="branchtatus"
                open-direction="bottom"
                :loading="isLoading"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
              />
            </div>
          </div>
          <div
            class="filter-margin uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
              <button
                class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                @click="clearFilter()"
              >
                <span
                  uk-icon="icon: close; ratio: 0.9"
                  class="uk-margin-small-right"
                />Clear
              </button>
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                @click="handleFind()"
              >
                <span
                  uk-icon="icon: search; ratio: 0.9"
                  class="uk-margin-small-right"
                />Cari
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-4@l">
        <router-link
          v-if="isCanAccessUser(`add`, `Cabang`)"
          class="uk-button uk-button-default"
          to="/admin/cabang/add"
        >
          <span
            uk-icon="icon: plus; ratio: 0.7"
            class="uk-margin-small-right"
          />Tambah Cabang
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import Datepicker from "vuejs-datepicker"
import { dateString } from "@/utils/formater"
import { isCanAccess } from "@/utils/auth"

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      statusChoose: "",
      metaFilter: {
        name: "",
        status: "",
        updated_start: "",
        updated_end: ""
      },
      branchtatus: [
        { name: "Aktif", value: "true" },
        { name: "Tidak Aktif", value: "false" }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meta: "branch/meta"
    })
  },
  watch: {
    "metaFilter.updated_start"() {
      this.metaFilter.updated_start = this.dateFormatFilter(this.metaFilter.updated_start)
    },
    "metaFilter.updated_end"() {
      this.metaFilter.updated_end = this.dateFormatFilter(this.metaFilter.updated_end)
    }
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapMutations({
      setMeta: "branch/SET_META"
    }),
    handleFind() {
      this.metaFilter.page = 1
      this.metaFilter.status = this.statusChoose ? (this.statusChoose.value ? this.statusChoose.value : "") : ""
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    async clearFilter() {
      this.metaFilter.name = ''
      this.metaFilter.status = ''
      this.metaFilter.updated_start = ''
      this.metaFilter.updated_end = ''
      this.statusChoose=''

      await this.handleFind()
    }
  }
}
</script>
