<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterBranch />
      <TableBranch />
    </div>
  </div>
</template>
<script>
import FilterBranch from './filter'
import TableBranch from './table'

export default {
  components: {
    FilterBranch,
    TableBranch
  }
}
</script>
