<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-shrink">
              <span class="uk-text-bold">Cabang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Nama Kepala Cabang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Nama Admin Sales</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Terakhir Diubah</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-table-small uk-text-center">
              <span class="uk-text-bold">Status</span>
            </th>
            <th class="uk-width-small uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="branches.meta.total_count>0">
            <tr
              v-for="(branch, i) in branches.data"
              :key="i"
            >
              <td>{{ branch.name || '-' }}</td>
              <td>{{ branch.branch_head_name || '-' }}</td>
              <td>{{ branch.data_analyst_name || '-' }}</td>
              <td>{{ formatDate(branch.updated_at) || '-' }}</td>
              <td>{{ branch.updated_by || '-' }}</td>
              <td>
                <label-status
                  style="margin:auto"
                  :status="branch.status"
                />
              </td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`edit`, `Cabang`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="showEdit(branch.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Cabang`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(branch.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="branches.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    LabelStatus
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      branches: 'branch/branches',
      meta: 'branch/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getBranch(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getBranch(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getBranch: 'branch/getBranch'
    }),
    ...mapMutations({
      setMeta: 'branch/SET_META'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showEdit(id) {
      this.$router.push(`/admin/cabang/edit/${id}`)
    },
    showDetail(id) {
      this.$router.push(`/admin/cabang/detail/${id}`)
    }
  }
}
</script>

<style scoped>
.uk-text-bold {
  padding: 8px 0;
}
</style>
